import { useSelector } from 'react-redux';
import Accordion from 'nxti/components/nx-accordion-widget/NXAccordionWidget';
import { vnd } from 'helpers/numberFormat';
import { formatLocalDate } from 'helpers/dateFormat';
import { Dialog } from '@mui/material';
import { DialogTabContent } from './dialog-content/dialog-content';
import { useCollectionActions } from './collection-info-container';

import './collection-info.scss';

const CONTRACT_STATUS = {
  OPEN: 'Đang vay',
  CLOSE: 'Đã đóng',
  OVERDUE: 'Quá hạn',
  EXPIRED: 'Hết hạn',
};

const COLLECTION_STATUS = {
  SUCCESS: 'Hết nợ',
  COLLECTED: 'Đã thu',
  NOTENOUGH: 'Chưa thu đủ',
  UNCOLLECTED: 'Chưa thu',
};

function RowCollectionInfo({ index, data }) {
  const [open, setOpen] = useState(false);

  const renderCollectionStatus = (status) => {
    return (
      <>
        {(status === 'NOTENOUGH' || status === 'UNCOLLECTED') && (
          <div className="collection-view__row__title__errorStatus">{COLLECTION_STATUS[status]}</div>
        )}
        {(status === 'SUCCESS' || status === 'COLLECTED') && (
          <div className="collection-view__row__title__infoStatus">{COLLECTION_STATUS[status]}</div>
        )}
      </>
    );
  };

  const renderCollectionContractStatus = (status) => {
    return (
      <>
        {(status === 'EXPIRED' || status === 'OVERDUE') && (
          <div className="collection-view__row__title__errorStatus">{CONTRACT_STATUS[status]}</div>
        )}
        {(status === 'OPEN' || status === 'CLOSE') && (
          <div className="collection-view__row__title__defaultStatus">{CONTRACT_STATUS[status]}</div>
        )}
      </>
    );
  };

  return (
    <div className="collection-view__row">
      <div className="collection-view__row__title">
        <div className="collection-view__row__title__titleText">
          {index}. Mã hợp đồng:{' '}
          <span
            className="collection-view__row__title__link"
            onClick={() => {
              setOpen(true);
            }}
          >
            {data.code ?? '-'}
          </span>
        </div>
        <div style={{ display: 'flex', gap: '3px' }}>
          <div>{renderCollectionStatus(data?.collectionInfor?.collectionStatus)}</div>
          <div>{renderCollectionContractStatus(data?.status)}</div>
        </div>
      </div>
      <div className="collection-view__row__item">
        <div>
          Số tiền phải thu:{' '}
          <span className="collection-view__row__item__value">
            {vnd(Number(data.collectionInfor.todCollectionMust)) ?? '-'}
          </span>
        </div>
        <div>
          Quá hạn: <span className="collection-view__row__item__value">{data.debtTracker.todDPD ?? '-'} ngày</span>
        </div>
      </div>
      <div className="collection-view__row__item">
        <div>Ngày đến hạn thanh toán:</div>
        <div className="collection-view__row__item__value">{formatLocalDate(data.debtTracker.dueDate) ?? '-'}</div>
      </div>
      <div className="collection-view__row__item">
        <div>Ngày thanh toán gần nhất:</div>
        <div className="collection-view__row__item__value">
          {formatLocalDate(data.debtTracker.lastPaymentDate) ?? '-'}
        </div>
      </div>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        onClose={(event, status) => {
          if (status === 'backdropClick') {
            setOpen(false);
          }
        }}
      >
        <DialogTabContent
          code={data.code}
          status={renderCollectionContractStatus(data?.status)}
          collectionStatus={renderCollectionStatus(data?.collectionInfor?.collectionStatus)}
          id={data.id}
        />
      </Dialog>
    </div>
  );
}

function CollectionInfoTab({ t, lead, defaultExpandState = false, label }) {
  const [expanded, setExpanded] = useState(false);
  const action = useCollectionActions();
  const collectionInfoData = useSelector((state) => state.Lead.collectionInfo);

  useEffect(() => {
    action.fetchCollectionInfo({
      payload: {
        idCardNumbers: lead.idCards.map((item) => item.number),
        customerId: lead.customerId,
      },
    });
  }, []);

  const handleOnChangeExpandStatus = (e, status) => {
    setExpanded(status);
  };

  return (
    <Accordion
      title="Thông tin thu hồi nợ"
      noShadow
      defaultExpandState={defaultExpandState}
      widget={
        <div className="collection-view">
          {collectionInfoData.map((item, index) => (
            <RowCollectionInfo index={index + 1} data={item} key={index} />
          ))}
        </div>
      }
      onChangeStatus={handleOnChangeExpandStatus}
    />
  );
}

export default memo(CollectionInfoTab);
