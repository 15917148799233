import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TablePagination } from '@mui/material';

export function CollectionInfoTable({
  columns,
  rows,
  pagination = undefined,
  onChangePage,
  onChangeRowsPerPage,
  summaryColumns = undefined,
  summaryRows = undefined,
  rowsPerPage = 10,
  page = 0,
}) {
  const handleChangePage = (e, page) => {
    onChangePage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    onChangeRowsPerPage(e.target.value);
  };

  return (
    <>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          count={pagination.totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ display: 'flex', justifyContent: 'end', padding: '0', marginTop: '-5px', border: '0px' }}
        />
      )}
      <TableContainer sx={{ overflow: 'auto', maxHeight: 400 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell key={index} align={item.align ?? 'left'}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((rowItem, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((colItem, colIndex) => (
                  <TableCell
                    key={colIndex}
                    align={colItem.align ?? 'left'}
                    style={rowIndex === rows.length - 1 && !summaryColumns ? { border: '0px' } : {}}
                  >
                    {colItem.render(rowItem)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          {summaryColumns && summaryRows && (
            <TableBody style={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
              {summaryRows.map((row, index) => (
                <TableRow key={index}>
                  {summaryColumns.map((col, colIndex) => (
                    <TableCell
                      key={colIndex}
                      colSpan={col?.colSpan}
                      align={col?.align}
                      style={index === summaryRows.length - 1 ? { border: '0px' } : {}}
                    >
                      {col.render(row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
