import { useDispatch } from 'react-redux';
import { flowRight as compose } from 'lodash-es';

import { fetchCollectionInfo, fetchCollectionPayment, fetchCollectionPlan } from 'redux/actions';
import CollectionInfoTab from './collection-info';

export const useCollectionActions = () => {
  const dispatch = useDispatch();

  const [actions] = useState({
    fetchCollectionInfo: compose(dispatch, fetchCollectionInfo),
    fetchCollectionPlan: compose(dispatch, fetchCollectionPlan),
    fetchCollectionPayment: compose(dispatch, fetchCollectionPayment),
  });

  return actions;
};

function CollectionInfoTabContainer({ ...rest }) {
  const dispatch = useDispatch();
  const { t } = useAppTranslation();

  const [props, setProps] = useState(null);

  useEffect(() => {
    setProps({
      t,
    });
  }, [t, dispatch]);

  return props ? <CollectionInfoTab {...props} {...rest} /> : null;
}

export default CollectionInfoTabContainer;
