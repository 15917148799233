import { Tab, Tabs } from '@mui/material';
import './dialog-content.scss';
import { CollectionPlan } from './collection-plan';
import { CollectionPayment } from './collection-payment';

export function DialogTabContent({ code, status, id, collectionStatus }) {
  const [value, setValue] = useState('plan');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="dialog-content">
      <div className="dialog-content__title">
        Mã hợp đồng {code} {collectionStatus} {status}
      </div>
      <div className="dialog-content__tab">
        <div className="dialog-content__tab__header">
          <Tabs value={value} onChange={handleChange}>
            <Tab value="info" label="Thông tin khoản vay" disabled />
            <Tab value="plan" label="Kế hoạch trả nợ" />
            <Tab value="payment" label="Lịch sử thanh toán" />
          </Tabs>
        </div>
        <div className="dialog-content__tab__content">
          {value === 'info' && <div></div>}
          {value === 'plan' && <CollectionPlan id={id} />}
          {value === 'payment' && <CollectionPayment id={id} />}
        </div>
      </div>
    </div>
  );
}
