import { ReactNode, SyntheticEvent } from 'react';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import './nx-accordion-widget.scss';

interface AccordionProps {
  title: string;
  widget: ReactNode;
  defaultExpandState?: boolean;
  onChangeStatus?: (e: SyntheticEvent | null, expanded: boolean) => void;
  style?: any;
  additionIcon?: ReactNode;
  noShadow?: boolean;
}

function NXAccordionWidget({
  title,
  widget,
  defaultExpandState = false,
  onChangeStatus = () => {},
  style = {},
  additionIcon,
  noShadow = false,
}: AccordionProps) {
  const [expanded, setExpanded] = useState(defaultExpandState);
  const [mounted, setMounted] = useState(defaultExpandState);

  useEffect(() => {
    onChangeStatus && onChangeStatus(null, defaultExpandState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleExpand = (e: SyntheticEvent, expanded: boolean) => {
    /* Always mounted after first toggle */
    setMounted(true);

    setExpanded((e) => !e);
    onChangeStatus && onChangeStatus(e, expanded);
  };

  return (
    <MuiAccordion
      className={`nx-accordion-widget nx-accordion-widget-root ${noShadow ? 'no-shadow' : ''}`}
      expanded={expanded}
      onChange={toggleExpand}
      style={style}
    >
      <MuiAccordionSummary className="nx-accordion-widget__summary" expandIcon={<ExpandMoreIcon />}>
        {title}
      </MuiAccordionSummary>
      {additionIcon}
      <MuiAccordionDetails>{mounted && widget}</MuiAccordionDetails>
    </MuiAccordion>
  );
}

export default NXAccordionWidget;
