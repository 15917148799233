const vnd = (number: number): string => {
  if (typeof number !== 'number') {
    return 'N/A';
  }
  const currencyValue = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
    maximumSignificantDigits: 20,
  })
    .format(number)
    .slice(0, -1)
    .concat('đ');
  return currencyValue;
};

const percent = (number: any, fixedNumber = 0): string => {
  if (number == null || number === 'N/A') {
    return 'N/A';
  }

  const parsedNumber = parseFloat(number);
  return number.toString().split('.').pop().length > 0 ? `${parsedNumber.toFixed(fixedNumber)}%` : `${parsedNumber}%`;
};

const formatNumber = (number: number, isFloat?: boolean, decimal = '.'): string => {
  if (`${number}`.indexOf(decimal) >= 0) {
    const decimalPos = `${number}`.indexOf(decimal);
    let leftSide = `${number}`.substring(0, decimalPos);
    let rightSide = `${number}`.substring(decimalPos);
    leftSide = leftSide
      .replace(/\D/g, '')
      .replace(/^0+(?!$)/gm, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    rightSide = rightSide.replace(/\D/g, '');
    if (isFloat === true) return `${leftSide},${rightSide}`;
    return leftSide;
  }
  return `${number}`
    .replace(/\D/g, '')
    .replace(/^0+(?!$)/gm, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const convertToNumber = (number: string, isFloat?: boolean): number => {
  let numberValue = '';
  if (`${number}`.indexOf(',') >= 0) {
    const decimalPos = `${number}`.indexOf(',');
    let leftSide = `${number}`.substring(0, decimalPos);
    let rightSide = `${number}`.substring(decimalPos);
    leftSide = leftSide.replace(/\D/g, '').replace(/^0+(?!$)/gm, '');
    rightSide = rightSide.replace(/\D/g, '');
    if (isFloat === true) return Number(`${leftSide}.${rightSide}`);
    return Number(leftSide);
  }
  numberValue = `${number}`.replace(/\D/g, '').replace(/^0+(?!$)/gm, '');
  return Number(numberValue);
};

export { vnd, percent, formatNumber, convertToNumber };
